@layer base {
  :root {
    /* #068099 */
    --primary: 6 128 153;
    --primary-light: #79cbdc;
    --primary-dark: #034958;
    --primary-texttransparent: #ffffff99;
    --primary-contrasttext: #ffffff;
    --background-base: #ffffff;

    /* #C44F4F */
    --secondary: 196 79 79;
    --secondary-dark: #7a3131;
    --secondary-light: #d27b7b;
    --secondary-texttransparent: #262729a6;
    --secondary-contrasttext: #ffffff;

    --additional: #3c9add;
    --additional-dark: #256089;
    --additional-texttransparent: #ffffffa6;

    --grey: #909498;
    --grey-light: #eef3f7;
    --grey-light-dark: #949799;
    --grey-dark: #4e5051;

    --text-disabled: #ffffff;
    --text-primary: #262729;
    --text-secondary: #26272999;
    --text-dark: #262729;
    --text-darktransparent: #262729a6;
    --text-light: #ffffff;
    --text-lighttransparent: #ffffffa6;

    --sale: #b71739;
    --sale-dark: #720e23;
    --sale-contrasttext: #ffffff;
    --sale-texttransparent: #ffffff99;

    --info: #8b969c;
    --info-light: #f1f2f3;
    --info-dark: #626566;
    --info-texttransparent: #ffffff99;
    --info-contrasttext: #262729;

    --success: #7dae2e;
    --success-light: #deebcb;
    --success-dark: #49661b;
    --success-texttransparent: #ffffff99;
    --success-contrasttext: #ffffff;

    --warning: #f5a623;
    --warning-light: #fce9c8;
    --warning-dark: #66450f;
    --warning-texttransparent: #ffffff99;
    --warning-contrasttext: #262729;

    --error: #d0021b;
    --error-light: #f3c0c6;
    --error-dark: #66010d;
    --error-texttransparent: #ffffff99;
    --error-contrasttext: #ffffff;

    --sustainability: #577f5b;
    --sustainability-light: #c3e6c7;
    --sustainability-dark: #425c45;
    --sustainability-texttransparent: #ffffff99;
    --sustainability-contrasttext: #ffffff;

    --payback: #0046aa;
    --payback-light: #ebf5fb;
    --payback-dark: #003eb0;

    --joe: #d6ab53;
    --joe-light: #f2e6ce;
    --joe-dark: #665228;

    --linear-progress-background: #a6c2c8;

    --carousel-dot-selected: #e0e0e0;
    --carousel-dot-hover: rgba(0, 0, 0, 0.05);
    --carousel-dot: #ffffff;

    --action-disabled: #b1b4b7;

    --paging-bar-disabled: #00000014;

    --eff-lbl-darkgreen: #008c3e;
    --eff-lbl-mediumgreen: #50a733;
    --eff-lbl-lightgreen: #cdd100;
    --eff-lbl-yellow: #ffea00;
    --eff-lbl-lightorange: #f8ba00;
    --eff-lbl-darkorange: #ec6707;
    --eff-lbl-red: #db0e15;

    --body1-fontsize: 1rem;
    --body1-lineheight: 1.375rem;

    --body2-fontsize: 0.875rem;
    --body2-lineheight: 1.188rem;

    --body3-fontsize: 0.75rem;
    --body3-lineheight: 1rem;

    --border-sm: 3px;
    --border-default: 8px;
    --border-md: 12px;
    --border-lg: 16px;
    --border-xl: 20px;
  }

  * {
    word-break: break-word;
    word-wrap: break-word;
  }

  /* body styling is necessary as a workaround for desktop navigation full-bleed background */
  body {
    width: 100%;
    overflow-x: hidden;
  }

  h1 {
    @apply text-size-h1;
  }

  h2 {
    @apply text-size-h2;
  }

  h3 {
    @apply text-size-h3;
  }

  h4 {
    @apply text-size-h4;
  }

  h5 {
    @apply text-size-h5;
  }

  h6 {
    @apply text-size-h6;
  }
}

@layer components {
  #onetrust-banner-sdk {
    height: auto !important;
    min-height: auto !important;
  }

  .button-primary {
    @apply bg-color-primary hover:bg-color-primary-dark focus-visible:bg-color-primary-dark text-color-text-light;
  }

  .button-secondary {
    @apply bg-color-secondary hover:bg-color-secondary-dark focus-visible:bg-color-secondary-dark text-color-text-light;
  }

  .price:has(.price-old-price) .price-value {
    color: theme('colors.color-sale.DEFAULT');
  }

  .price-old-price {
    text-decoration: line-through;
  }

  .page-logo {
    @apply h-[25px] w-[125px] xl:h-[48px] xl:w-[200px];
  }

  .button {
    @apply rounded-full;
  }

  .pagination-item {
    @apply rounded-full;
  }

  .toggle-group-item {
    @apply first:rounded-l-full last:rounded-r-full;
  }

  .checkbox-error {
    box-shadow: inset 0 0 0 1px theme('colors.color-error.DEFAULT');
  }

  .input-error {
    box-shadow: inset 0 0 0 4px theme('colors.color-error.light');
  }

  .input-active {
    box-shadow: inset 0 0 0 4px theme('colors.color-primary.light');
  }

  .info-chip {
    @apply text-color-info-dark border-color-info-dark bg-color-background-base hover:bg-color-info-light hover:text-color-info-dark;
  }

  .info-chip-filled {
    @apply text-color-info-dark hover:text-color-text-light border-color-info-dark bg-color-info-light;
  }

  .info-chip-filled-selected {
    @apply text-color-text-light border-color-info bg-color-info-dark;
  }
}
