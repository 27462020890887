@layer base {
  :root {
    /* #000000 */
    --primary: 0 0 0;
    --primary-dark: #000000;
    --primary-light: #3f3f3f;
    --primary-texttransparent: #ffffff99;

    /* #f5e600 */
    --secondary: 245 230 0;
    --secondary-dark: #998f00;
    --secondary-light: #f7ec3f;

    --sustainability: #b4d2c8;
    --sale: 245 230 0;

    --grey: #b4b4b4;
    --grey-light: #f0f0f0;
    --grey-light-dark: rgb(180, 180, 180);
    --grey-dark: #9a9994;

    --linear-progress-background: #9e9e9e;

    --text-disabled: #4e5051;
    --text-primary: #000000;
    --text-secondary: #4b4b4a99;
    --text-dark: #000000;
    --text-darktransparent: #4b4b4aa6;
    --text-light: #ffffff;
    --text-lightTransparent: #ffffffa6;

    --border-sm: 3px;
    --border-default: 3px;
    --border-md: 12px;
    --border-lg: 16px;
    --border-xl: 20px;
  }
}

@layer components {
  .price:has(.price-old-price) .price-value {
    color: var(--text-dark);
  }

  .page-logo {
    @apply w-[75px] xl:w-[143px];
  }

  .button {
    @apply rounded;
  }

  .pagination-item {
    @apply rounded;
  }

  .button-secondary {
    @apply text-color-text-dark;
  }

  .toggle-group-item {
    @apply first:rounded-l last:rounded-r;
  }
}
